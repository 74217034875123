import React, { Component, Fragment } from 'react';
import mixpanel from 'mixpanel-browser';

import Header from 'components/header';
import Footer from 'components/footer';
import { MIXPANEL_PROJECT_TOKEN } from 'config';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }
  componentDidMount() {
    mixpanel.init(MIXPANEL_PROJECT_TOKEN);
  }
  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
  }
  render() {
    if (this.state.hasError) {
      return (
        <Fragment>
          <Header />
          <div className="error-page">
            <div className="error-block">
              <img
                src="https://edly-edx-theme-files.s3.amazonaws.com/error-page-assets/error-500.svg"
                alt="Error 500"
              />
              <h1>INTERNAL SERVER ERROR!</h1>
              <h3 className="copy">
                There seems to be a small problem. Please refresh this page after a few seconds.
                <br />
                If you continue to have issues, please contact Edly <a href="mailto:support@edly.io"> Support</a>.
              </h3>
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
